<template lang="pug">
  .modal-wrapper
    .modal-wrapper-top(:class="modalWrapperTopClasses")
      img.logo-top(v-show="getIcon" :src="pathToSvg")
      .modcsoff-wrapper(@click="closeModal")
        img.crossm(:src="require('@/assets/images/personal/sunduk-white/cross_starz57.svg')" alt="cross")

    .modal-wrapper-content(v-show="!getInputFieldsBool" :class="{ 'restricted': getLevel == 'error' || getLevel == 'warning' }")
      .nm-title {{ getLevel }}
      span.contarr(v-if="Array.isArray(getText)")
        span(v-for="(msg, index) in getText" :key="index") {{ msg }}
      span(v-if="!Array.isArray(getText)") {{ getText }}

    .modal-wrapper-form(v-show="getInputFieldsBool")
      .nm-title {{ getTitle }}

      span(v-if="getAcceptFunc == '2FA' || 'verifyPhone'") {{ getText }}
      form.form-wrapper
        .field-wrapper(v-for="(item, idx) in inputFieldsArr" :key="idx")
          span.error(v-show="item.errorText") {{ item.errorText }}
          input(:type="item.typeString" :ref="item.name" :name="item.name"
            autocomplete="off" :id="item.name" v-model="inputFieldsData[item.name]"
            :class="{ 'hd-pass': item.it_pass }" @input="handleInput($event, item.name)"
            @focus="focusHandler($event, item.name)"
            @blur="blurHandler($event, item.name)")
          span.floating-label(:ref="`${item.name}|fls`"
                              :class="{ 'chg-pass': setClassesFlLabel }") {{ item.placeholder }}
          .opcl-container(v-if="item.hasOwnProperty('isOpenIcon')")
            img.eye(v-if="item.isOpenIcon" :src="require('@/assets/images/auth/eye.svg')"
              @click="switchPasswordType(item.name)")
            img.cl-eye(v-else :src="require('@/assets/images/auth/closed_eye.svg')"
              @click="switchPasswordType(item.name)")

    .modal-wrapper-button(v-show="buttonsType !== ''" :class="{ 'inputs': getInputFieldsBool }")
      Button.ok(:title="getFormAcceptButtonLabel" :funcToBeCalled="getAcceptFunc"
        @emitButton="listenEmitButton")

      .double-buttons(v-show="buttonsType === 'ok_cancel' || buttonsType === 'yes_no'")
        Button.dbs-ok(v-if="showDoubleButtons" :title="dbsOkTitle" :funcToBeCalled="getAcceptFunc")
        Button.dbs(v-if="showDoubleButtons" :title="dbsOkTitle" :funcToBeCalled="getRejectFunc")

</template>

<script>

import Button from '@/components/serviceComponents/ButtonSW';
import Modal from './Modal.vue';

export default {
  extends: Modal,
  components: {
    Button,
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/serviceComponents/modal_sw.scss";
</style>
